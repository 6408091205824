import { makeStyles } from '@mui/styles';
import React from 'react';
import CircularSkeleton from './CircularSkeleton';
import TextSkeleton from './TextSkeleton';

const useStyles = makeStyles((theme) => ({
  dropdownContent: {
    borderBottom: `1px solid ${theme.palette.grayMid3}`,
    display: 'flex',
    flexDirection: 'row',
    padding: '0.5rem',
    marginBottom: '0.5rem',
    gap: '0.5rem',
  },
}));

export default function Notificationkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.dropdownContent}>
      <CircularSkeleton width="1rem" height="1rem" />
      <div>
        <TextSkeleton fontSize="0.75rem" width="10rem" />
        <TextSkeleton fontSize="0.625rem" width="7rem" />
      </div>
    </div>
  );
}
