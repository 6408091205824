import { Skeleton } from '@mui/material';
import React from 'react';

export default function TextSkeleton(props) {
  /* adjust the height via font-size */
  const { fontSize, width = '100%' } = props;
  return (
    <Skeleton variant="text" sx={{ fontSize }} width={width} />
  );
}
