export const services = {
  AUTH_SERVICE_URI: '/auth-service',
  APPLICATION_SERVICE_URI: '/applications',
};

export const authService = {
  SWAP_TOKEN: '/auth/swap-token',
  AUTHENTICATE: '/authenticate',
  CREATE_USER: '/auth/create-user',
  LOGOUT: '/logout',
};

export const applicationService = {
  GET_APPLICATIONS: '/applications',
  GET_USPS_DOCUMENTS: '/usps-documents',
  DOCUMENT_SPLIT_DETAILS: (documentId) => `/usps-documents/${documentId}`,
  LINK_UNLINK_USPS_DOCUMENTS: '/applications/SYSTEM/usps-documents',
  GET_APPLICATION_DETAILS: (applicationId) => `/applications/${applicationId}`,
  GET_APPLICATION_STATUS: (applicationId) => `/applications/${applicationId}?field=status`,
  GET_SYSTEM_DETAILS: '/applications/SYSTEM',
  GET_APPLICATION_DOCUMENTS: (applicationId) => `/applications/${applicationId}/documents`,
  GET_SYSTEM_DOCUMENTS: (isLinked, pageNumber, pageSize) => `/applications/SYSTEM/documents?is_linked=${isLinked}&page_number=${pageNumber}&page_size=${pageSize}`,
  GET_SYSTEM_DOCUMENTS_DATA_FALSE: (isLinked) => `/applications/SYSTEM/documents?is_linked=${isLinked}`,
  GET_PRESIGNED_URL: (applicationId, documentId) => `/applications/${applicationId}/documents/${documentId}/presignedurl`,
  GET_APPLICATION_NOTES: (applicationId) => `/applications/${applicationId}/notes`,
  GET_EXTRACTION_DETAILS: (applicationId, documentId) => `/applications/${applicationId}/documents/${documentId}/extraction`,
  GET_EMAIL_TEMPLATES: (applicationId) => `/applications/${applicationId}/email-templates`,
  SEND_EMAIL: (applicationId) => `/applications/${applicationId}/email`,
  GET_CLASSIFICATION_TYPES: '/classification-types',
  APPROVE_CLASSIFICATION: (applicationId) => `/applications/${applicationId}/documents/classification`,
  APPROVE_SYSTEM_CLASSIFICATION: '/applications/SYSTEM/documents/classification',
  GET_PRODUCT_TYPES: '/product-types',
  GET_PRODUCT_COLLATERAL_TYPES: (productTypeId) => `/products/${productTypeId}/collateral_types`,
  GET_USER_ROLES: '/roles',
  GET_USER_ROLE: '/role',
  GET_USERS: '/users',
  GET_USER: '/user',
  PORTAL_ACCESS: '/portal_accesses',
  GET_PORTAL_INFO: (portalAccessId) => `/portal_accesses/${portalAccessId}/portal_info`,
  GET_NOTIFICATION: (pageSize, currPage) => `/notifications?page_size=${pageSize}&page_number=${currPage}`,
  PUT_NOTIFICATION: '/notifications',
  GET_COMPLIANCE_RULES: (applicationId, param) => `/applications/${applicationId}/collaterals/rules?show=${param}`,
  GET_INNER_COMPLIANCE_RULES: (applicationId) => `/applications/${applicationId}/collaterals/rules`,
  GET_ADMIN_COMPLIANCE_RULES: (productTypeId, collateralTypeId) => `/products/${productTypeId}/collateral_types/${collateralTypeId}`,
  GET_INSURANCE_COVERAGES: (applicationId) => `/applications/${applicationId}/insurances`,
  GET_INSURANCE_COVERAGES_HITL: (applicationId, collateralId, insuranceId) => `applications/${applicationId}/collaterals/${collateralId}/insurances/${insuranceId}`,
  CALCULATE_RULES: '/calculate_rules',
  UPDATE_INSURANCE_AS_FORCE_PLACED: (applicationId, insuranceId) => `/applications/${applicationId}/insurances/${insuranceId}`,
  UPDATE_SYSTEM_DOCUMENT_DETAILS: (documentId) => `/applications/SYSTEM/documents/${documentId}`,
  GET_COLLATERAL_DETAILS: (ingestionId) => `/collateral-details/${ingestionId}`,
  CONFIRM_EXTARCTION: (applicationId) => `/applications/${applicationId}/documents`,
  GET_REPORTS: '/reports',
  GET_KPIS: (params) => `/kpis?kpi_type=${params}`,
  GET_NOTICE: (applicationId, insuranceId, time) => `/applications/${applicationId}/insurances/${insuranceId}/notice?current_time=${time}`,
  POST_NOTICE: (applicationId, insuranceId, noticeId, flag) => `/applications/${applicationId}/insurances/${insuranceId}/notice?notice_id=${noticeId}&send_email=${flag}`,
  GET_ACTIVITY_LOG_DETAILS: (applicationId) => `get-activity-logs?application_id=${applicationId}`,
  GET_AGENT_ACTIVITY_LOG_DETAILS: (applicationId) => `agent-history?application_id=${applicationId}`,
  GET_REPORT_DOWNLOAD: (applicationId, type) => `/applications/${applicationId}/reports?type=${type}`,
  GET_RECENTLY_WORKED: 'get-activity-logs',
  GET_EXTRACTION_DROPDOWN_DETAILS: (clfType, subClfType) => `extraction-keys?clf_type=${clfType}&subclf_type=${subClfType}`,
  GET_SEARCH_SUGGESTIONS: (searchTerm, pageType) => `suggestions?search=${searchTerm}&page_type=${pageType}`,
  GET_DOCUMENT_SEARCH: (searchKey, saerchValue) => `/search?key=${searchKey}&value=${saerchValue}`,
  GET_DOCUMENT_SEARCH_RESULTS: '/get-search-details',
  GET_DOCUMENT_COLL_SEARCH_RESULTS: '/get-search-details?key=collateral_address',
  DOCUMENT_REVIEW: '/applications/SYSTEM/documents',
  GET_DOWNLOAD_NOTICE: (portalAccessId) => `/portal_accesses/${portalAccessId}/notice`,
  GET_CEASE_TRACKING: (applicationId, collateralId) => `/applications/${applicationId}/collaterals/${collateralId}`,
  GET_COLLATERAL_ADDRESS_DETAILS: (applicationId) => `/applications/${applicationId}/collateral-info`,
  GET_SUMMARY: (applicationId) => `/applications/${applicationId}/summary`,
  GET_APP_STATUS: (appId, docId) => `/applications/${appId}/documents/${docId}`,
  RE_EXTRACT: (appId, docID) => `/applications/${appId}/documents/${docID}/retry-extraction`,
  RE_EXTRACT_ALL_DOCS: (appId) => `/applications/${appId}/retry-extraction`,
  PATCH_UPLOAD_PORTAL: (appId) => `/applications/${appId}/consent`,
  AUTOMATED_NOTICES: (appId) => `/applications/${appId}/automated-notice`,
  AGENT_HITL: (applicationId, collateralId) => `/applications/${applicationId}/collaterals/${collateralId}/agent`,
  REPORT_INFO: '/report-info',
  GET_GLOBAL_REPORT: (type) => `/general-global-report?report_type=${type}`,
  RESOLVED_NOTIFICATION: (notificationId) => `/notifications/${notificationId}`,
  DELETE_DOCUMENT: () => '/applications/SYSTEM/documents',
};

export const opsDashboardService = {
  FETCH_DOCUMENT_STATISTICS_KPI_INFO: '/kpis/document_statistics',
  FETCH__APPLICATION_STATISTICS_KPI_INFO: '/kpis/application_statistics',
};
