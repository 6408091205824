import React, {
  createContext, useContext, useState, useMemo, useEffect,
} from 'react';

const pageContext = createContext();
const getInitialPageState = () => {
  const pageNumber = localStorage.getItem('pageNumber');
  return pageNumber ? +pageNumber : 1;
};
export function PageContextProvider({ children }) {
  const [pageNumber, setPageNumber] = useState(getInitialPageState);

  useEffect(() => {
    localStorage.setItem('pageNumber', pageNumber);
  }, [pageNumber]);

  const handlePagination = (pageNavigation) => {
    if (pageNavigation === 'prev') {
      if (pageNumber === 2) {
        setPageNumber(pageNumber - 1);
      }
    }
    if (pageNavigation === 'next') {
      if (pageNumber === 1) {
        setPageNumber(pageNumber + 1);
      }
    }
  };
  const pageNumberValue = useMemo(() => ({
    pageNumber, handlePagination,
  }), [pageNumber]);
  return (
    <pageContext.Provider value={pageNumberValue}>
      {children}
    </pageContext.Provider>
  );
}
export function usePage() {
  return useContext(pageContext);
}
